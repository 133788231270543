import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "../../button";
import { isDownloading } from "../../../shared-logic/useDownloadFile";

export function DocumentMenu({
  contentType,
  docId,
  fileName,
  onDownloadDocument,
  isDownloading,
}: {
  contentType: string | undefined;
  docId: string | undefined;
  fileName: string | undefined;
  onDownloadDocument: (
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ) => Promise<void>;
  isDownloading: isDownloading;
}) {
  if (!docId || !fileName) return null;

  const isDocDownloading = isDownloading[docId]?.downloading ?? false;

  return (
    <>
      {isXml(contentType) ? (
        <>
          <Button
            mr={3}
            leftIcon={<DownloadIcon />}
            onClick={() => onDownloadDocument(docId, fileName, "pdf")}
            isLoading={isDocDownloading && isDownloading[docId]?.type === "pdf"}
          >
            PDF
          </Button>
          <Button
            mr={3}
            leftIcon={<DownloadIcon />}
            onClick={() => onDownloadDocument(docId, fileName, "html")}
            isLoading={isDocDownloading && isDownloading[docId]?.type === "html"}
          >
            HTML
          </Button>
          <Button
            leftIcon={<DownloadIcon />}
            onClick={() => onDownloadDocument(docId, fileName)}
            isLoading={isDocDownloading && isDownloading[docId]?.type === "xml"}
          >
            XML
          </Button>
        </>
      ) : (
        <Button
          leftIcon={<DownloadIcon />}
          onClick={() => onDownloadDocument(docId, fileName)}
          isLoading={isDocDownloading}
        >
          Download
        </Button>
      )}
    </>
  );
}

export function isXml(mimeType: string | undefined): boolean {
  return mimeType ? mimeType.includes("xml") : false;
}

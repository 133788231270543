import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { Condition, DiagnosticReport, Location, Practitioner } from "@medplum/fhirtypes";
import { toTitleCase } from "@metriport/shared";
import { Report, getReportTypeByLoinc } from "./table-data";

export function ReportContent({ encounterNote }: { encounterNote: Report }) {
  return (
    <Box w={"100%"}>
      <Box mb={4}>
        <TypeOfReport diagnosticReports={encounterNote.diagnosticReports} />
        <Participants participants={encounterNote.participants} />
        <Locations locations={encounterNote.locations} />
      </Box>
      <ReportNotes reports={encounterNote.diagnosticReports} />
    </Box>
  );
}

function TypeOfReport({ diagnosticReports }: { diagnosticReports: DiagnosticReport[] }) {
  const reportType = getReportTypeByLoinc(diagnosticReports);

  return (
    <>
      <Box display={"flex"}>
        <Text w={"120px"} m={0} mr={2} fontSize={18} fontWeight="bold">
          Type(s):
        </Text>
        <Box>
          <Text m={0} fontSize={18}>
            {reportType}
          </Text>
        </Box>
      </Box>
    </>
  );
}

function Participants({ participants }: { participants: Practitioner[] }) {
  return (
    <>
      <Box display={"flex"}>
        <Text w={"120px"} m={0} mr={2} fontSize={18} fontWeight="bold">
          Provider(s):
        </Text>
        <Box>
          {participants.map((participant, i) => (
            <Text key={i} m={0} fontSize={18}>
              {participant.name?.[0]?.given?.[0]} {participant.name?.[0]?.family}
              {i < participants.length - 1 ? ", " : ""}
            </Text>
          ))}
        </Box>
      </Box>
    </>
  );
}

function Locations({ locations }: { locations: Location[] }) {
  return (
    <>
      <Box display={"flex"}>
        <Text w={"120px"} m={0} mr={2} fontSize={18} fontWeight="bold">
          Location(s):
        </Text>
        <Box>
          {locations?.map((location, i) => (
            <Text key={i} m={0} fontSize={18}>
              {location.name}
              {i < locations.length - 1 ? ", " : ""}
            </Text>
          ))}
        </Box>
      </Box>
    </>
  );
}

export function getDiagnosisText(diagnosis: Condition): string | undefined {
  const displayText = diagnosis.code?.coding
    ?.flatMap(coding => coding.display?.trim() || [])
    .join(", ");
  return (
    diagnosis.code?.text ??
    (displayText && displayText.length ? toTitleCase(displayText) : undefined)
  );
}

function ReportNotes({ reports }: { reports: DiagnosticReport[] }) {
  return (
    <Box>
      <Box>
        {reports?.map((report, i) => (
          <ReportNote key={i} report={report} />
        ))}
      </Box>
    </Box>
  );
}

function ReportNote({ report }: { report: DiagnosticReport }) {
  const cleanNote = getCleanNoteFromReport(report);

  if (!cleanNote) {
    return null;
  }

  return (
    <>
      <Box display={"flex"} flexDirection="column">
        <Box
          px={4}
          py={2}
          border="solid"
          borderRadius={10}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          mb={5}
          whiteSpace={"pre-line"}
        >
          {cleanNote}
        </Box>
      </Box>
    </>
  );
}

export function getCleanNoteFromReport(report: DiagnosticReport): string {
  const note = report.presentedForm?.[0]?.data || "";
  const noJunkNote = removeEncodedStrings(note);
  const decodeNote = atob(noJunkNote);
  return cleanUpNote(decodeNote);
}

const REMOVE_FROM_NOTE = [
  "xLabel",
  "5/5",
  "Â°F",
  "â¢",
  "documented in this encounter",
  "xnoIndent",
  "Formatting of this note might be different from the original.",
  "<content>",
  "</content>",
  "<root>",
  "</root>",
  "&lt;",
  "&gt;",
];

function cleanUpNote(note: string): string {
  return note
    .trim()
    .replace(new RegExp(REMOVE_FROM_NOTE.join("|"), "g"), "")
    .replace(/<ID>.*?<\/ID>/g, "")
    .replace(/<styleCode>.*?<\/styleCode>/g, "");
}

function removeEncodedStrings(valueString: string): string {
  return valueString.replace(/&#x3D;/g, "").trim();
}

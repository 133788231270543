import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { PatientTab } from "../shared-logic/consolidated-context/reducer";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { isMrDownloading } from "../shared-logic/useGetMedicalRecord";
import { Button as MetriportButton } from "./button";
import { IdContainer } from "./id-copy-container";

export function PatientsHeader({
  patient,
  isQueryingDocuments,
  isFetchingPatient,
  isDownloadingMr,
  selectedTab,
  lastDocQuery,
  actions,
}: {
  patient: PatientDTO | undefined;
  isFetchingPatient: boolean;
  isQueryingDocuments: boolean;
  isDownloadingMr: isMrDownloading;
  selectedTab: PatientTab;
  lastDocQuery: string | undefined;
  actions: {
    onQueryPatientDocuments: ({ override }: { override?: boolean }) => Promise<void>;
    onGenerateMedicalRecord: () => void;
    onDisplayPatientDemos: () => void;
    onEditPatient: (() => void) | undefined;
    onViewPatientMatches: (() => void) | undefined;
    onSetPatientTab: (tab: PatientTab) => void;
  };
}) {
  const bg = useColorModeValue("gray.50", "gray.800");

  const isDownloadingMR = Object.values(isDownloadingMr).some(({ downloading }) => downloading);

  function openPatientMatches() {
    if (actions.onViewPatientMatches) actions.onViewPatientMatches();
    actions.onSetPatientTab("matches");
  }

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} top={0} position={"relative"}>
      <LastQueried lastDocQuery={lastDocQuery} />
      <Box
        bg={useColorModeValue("white", "gray.800")}
        border={"solid"}
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        px={7}
        py={5}
      >
        <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between">
          <Flex justifyContent={"space-between"}>
            <PatientInfo
              patient={patient}
              isLoading={isFetchingPatient}
              actions={{ onEditPatient: actions.onEditPatient }}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={{ base: "flex-start", xl: "flex-end" }}
          >
            <Flex>
              <MetriportButton
                mr={5}
                isLoading={isDownloadingMR}
                onClick={actions.onGenerateMedicalRecord}
              >
                Generate Medical Record
              </MetriportButton>
              <Button
                mr={5}
                onClick={() => actions.onQueryPatientDocuments({ override: true })}
                isLoading={isQueryingDocuments}
                variant={"outline"}
                borderColor={METRIPORT_PRIMARY}
                color={METRIPORT_PRIMARY}
              >
                Request Patient Data
              </Button>
              {actions.onViewPatientMatches && (
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<BsThreeDotsVertical />}
                    variant="outline"
                  />
                  <MenuList bg={bg}>
                    <MenuItem onClick={openPatientMatches} bg={bg}>
                      View Patient Matches
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
        </Flex>
        <PatientDemo patient={patient} actions={actions} />
        <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={5} />
        <Flex justifyContent={"center"} mt={5}>
          <Box>
            <MetriportButton
              mr={5}
              variant={selectedTab === "fhir" ? "solid" : "outline"}
              onClick={() => actions.onSetPatientTab("fhir")}
            >
              View Summary
            </MetriportButton>
            <MetriportButton
              variant={selectedTab === "documents" ? "solid" : "outline"}
              mr={5}
              onClick={() => actions.onSetPatientTab("documents")}
            >
              View Raw Documents
            </MetriportButton>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

function LastQueried({ lastDocQuery }: { lastDocQuery: string | undefined }) {
  return (
    <Box
      display={{ base: "none", "2xl": "block" }}
      position={"absolute"}
      top={0}
      marginLeft={"auto"}
      marginRight={"auto"}
      left={0}
      right={0}
      border={"solid"}
      borderTop={0}
      borderBottomRadius={10}
      borderColor={METRIPORT_PRIMARY}
      width={"fit-content"}
      paddingX={4}
      paddingY={2}
    >
      <Text color={METRIPORT_PRIMARY}>Last Queried: {lastDocQuery ?? "-"}</Text>
    </Box>
  );
}

function PatientInfo({
  patient,
  isLoading,
  actions,
}: {
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions: {
    onEditPatient: (() => void) | undefined;
  };
}) {
  const patientName = `${patient?.firstName} ${patient?.lastName}`;

  return (
    <Flex mb={{ base: 2, xl: 0 }} alignItems={"center"}>
      <Flex w={"100%"} flexDirection={"column"}>
        <Skeleton display={"flex"} flexDirection={"row"} alignItems="center" isLoaded={!isLoading}>
          <Text fontSize="2xl" fontWeight={"bold"}>
            {patientName}
          </Text>
          {actions.onEditPatient && (
            <IconButton
              onClick={actions.onEditPatient}
              bg={"transparent"}
              _hover={{ color: "#879ced" }}
              aria-label="Edit Patient"
              icon={<EditIcon />}
            />
          )}
        </Skeleton>
      </Flex>
    </Flex>
  );
}

function PatientDemo({
  patient,
  actions,
}: {
  patient: PatientDTO | undefined;
  actions: {
    onDisplayPatientDemos: () => void;
  };
}) {
  const demoFields: Array<{ label: string; value: string | undefined }> = [
    { label: "id", value: patient?.id },
    { label: "dob", value: patient?.dob },
    { label: "gender", value: patient?.genderAtBirth },
  ];

  return (
    <Flex alignItems={"center"}>
      {demoFields.map(({ label, value }, i) => (
        <Flex
          mb={{ base: 3, "2xl": 0 }}
          mt={{ base: 3, "2xl": 0 }}
          ps={i === 0 ? 0 : 2}
          pe={2}
          borderRight={"solid"}
          borderColor={useColorModeValue("gray.300", "gray.600")}
          key={i}
        >
          <Box>
            <Flex alignItems={"center"}>
              {label === "id" ? (
                <IdContainer value={value} entity={"Patient"} />
              ) : (
                <Text>{value}</Text>
              )}
            </Flex>
          </Box>
        </Flex>
      ))}
      <Icon ml={2} cursor={"pointer"} onClick={actions.onDisplayPatientDemos} as={FiInfo} />
    </Flex>
  );
}

import { useColorMode } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { AgGridReact } from "ag-grid-react";
import { IdContainer } from "@metriport/shared-internal";
import { Facility } from "@metriport/api-sdk";
import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
// import dayjs from "dayjs";

// React Grid Logic
import "ag-grid-community/styles/ag-grid.css";
// Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../grid.css";

export default function FacilityGrid({
  facilities,
  search,
  onEditFacility,
  isLoading,
}: {
  facilities: Facility[] | null;
  search: string;
  onEditFacility: (facility: Facility) => void;
  isLoading: boolean;
}) {
  const { colorMode } = useColorMode();

  const rowData = facilities?.map(facility => ({
    id: facility.id,
    name: facility.name,
    state: facility.address.state,
  }));

  const columnDefs: ColDef[] = [
    {
      field: "id",
      onCellClicked: () => null,
      cellRenderer: (params: { value: string }) => (
        <IdContainer value={params.value} entity={"Facility"} full={true} />
      ),
      maxWidth: 350,
    },
    { field: "name" },
    { field: "state", maxWidth: 250 },
    {
      field: "actions",
      maxWidth: 250,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (!facilities) {
          return null;
        }

        const facility = facilities.find(f => f.id === props.data.id);

        if (!facility) {
          return null;
        }

        return (
          <IconButton
            onClick={() => onEditFacility(facility)}
            bg={"#748df0"}
            color={"white"}
            _hover={{ bg: "#879ced" }}
            aria-label="Edit Facility"
            icon={<EditIcon />}
          />
        );
      },
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
  };

  return (
    <AgGridReact
      className={colorMode === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
      loading={isLoading}
      headerHeight={60}
      groupDefaultExpanded={-1}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowData={rowData}
      quickFilterText={search}
      pagination={true}
      paginationPageSize={20}
      suppressCellFocus={true}
    />
  );
}

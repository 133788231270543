import { Flex, Icon, Text } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useCopyToClipboard } from "usehooks-ts";
import { limitStringLength } from "../../../shared/util";
import useMetriportToast from "../shared-logic/useMetriportToast";

export function IdContainer({
  value,
  entity,
  full,
}: {
  value: string | undefined;
  entity: string;
  full?: boolean;
}) {
  const toast = useMetriportToast();
  const [, copy] = useCopyToClipboard();

  if (!value) return null;

  return (
    <Flex
      onClick={() => {
        copy(value);
        toast.success({
          title: `Copied ${entity} ID to clipboard`,
        });
      }}
      cursor={"pointer"}
      alignItems={"center"}
    >
      <Text>{full ? value : limitStringLength(value, 15)}</Text>
      <Icon ml={2} as={FiCopy} />
    </Flex>
  );
}

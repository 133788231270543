import { useEffect, useState } from "react";
import { ListDocumentResult } from "@metriport/api-sdk";
import dayjs from "dayjs";
import { ISO_DATE } from "@metriport/shared/common/date";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
import { getDocumentQueryStatus } from "../../../api/medical-record";

export type SearchDocsFilters = {
  dateFrom?: string;
  dateTo?: string;
  content?: string;
  setFiltersInStorage?: boolean;
};

type UseQueryDocuments = {
  isQueryingDocuments: boolean;
  downloadProgress: { total: number; completed: number };
  lastDocQuery: string | undefined;
  documentListResult: ListDocumentResult;
  onQueryPatientDocuments: ({ override }: { override?: boolean }) => Promise<void>;
  onSearchPatientDocuments: (filters: SearchDocsFilters) => Promise<void>;
  searchDocFilters: SearchDocsFilters | undefined;
};

const POLLING_INTERVAL = dayjs.duration(3, "second").asMilliseconds();

export function useQueryDocuments({
  patientId,
  disabled,
}: {
  patientId: string;
  disabled?: boolean;
}): UseQueryDocuments {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [searchDocFilters, setSearchDocFilters] = useState<SearchDocsFilters | undefined>(
    undefined
  );
  const [isQueryingDocuments, setIsQueryingDocuments] = useState(true);
  const [isPolling, setIsPolling] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState({ total: 0, completed: 0 });
  const [lastDocQuery, setLastDocQuery] = useState<string | undefined>(undefined);
  const [documentListResult, setDocuments] = useState<ListDocumentResult>({
    documents: [],
  });

  useEffect(() => {
    const filters = localStorage.getItem("doc_filters");
    if (filters) {
      const parsedFilters = JSON.parse(filters);
      const patientFilters = parsedFilters?.[patientId];
      setSearchDocFilters(patientFilters);
    }

    if (!disabled) {
      onQueryPatientDocuments({});
    } else {
      setIsQueryingDocuments(false);
    }
  }, []);

  async function onQueryPatientDocuments({ override }: { override?: boolean }) {
    try {
      setIsQueryingDocuments(true);
      const documentList = await metriportApi.listDocuments(patientId);

      if (documentList.documents.length === 0 || override) {
        await metriportApi.startDocumentQuery(patientId);
        setIsPolling(true);
      } else {
        const resp = await getDocumentQueryStatus(patientId);
        setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
        setIsQueryingDocuments(false);
        searchDocumentsByFilters({ documents: documentList });
      }
    } catch (error) {
      capture.error(error, {
        extra: { patient: patientId, context: `patient.file.list` },
      });
      toast.error();
    }
  }

  useInterval(
    async () => {
      const { download } = await metriportApi.getDocumentQueryStatus(patientId);
      const successful = download?.successful ?? 0;
      const errors = download?.errors ?? 0;
      const isProcessing = download?.status === "processing";

      setIsPolling(isProcessing);
      setDownloadProgress({
        total: download?.total ?? 0,
        completed: successful + errors,
      });

      if (download?.status === "completed") {
        const documentList = await metriportApi.listDocuments(patientId);
        const resp = await getDocumentQueryStatus(patientId);
        setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
        searchDocumentsByFilters({ documents: documentList });
        setIsQueryingDocuments(false);
      }
    },
    isPolling ? POLLING_INTERVAL : null
  );

  async function searchDocumentsByFilters({ documents }: { documents: ListDocumentResult }) {
    if (searchDocFilters) {
      const { dateFrom, dateTo, content } = searchDocFilters;
      onSearchPatientDocuments({ dateFrom, dateTo, content, setFiltersInStorage: false });
    } else {
      setDocuments(documents);
    }
  }

  async function onSearchPatientDocuments({
    dateFrom,
    dateTo,
    content,
    setFiltersInStorage = true,
  }: SearchDocsFilters) {
    if (setFiltersInStorage) {
      const filters = localStorage.getItem("doc_filters");
      const globalFilters = filters ? JSON.parse(filters) : {};

      const newFilters = {
        ...globalFilters,
        [patientId]: {
          dateFrom,
          dateTo,
          content,
        },
      };

      localStorage.setItem("doc_filters", JSON.stringify(newFilters));
      setSearchDocFilters({
        dateFrom,
        dateTo,
        content,
      });
    }

    setIsQueryingDocuments(true);

    try {
      const dateFromValue = getFilterValue(dateFrom);
      const dateToValue = getFilterValue(dateTo);
      const contentValue = getFilterValue(content);

      const documentList = await metriportApi.listDocuments(patientId, {
        dateFrom: dateFromValue,
        dateTo: dateToValue,
        content: contentValue,
      });

      setDocuments(documentList);
    } catch (error) {
      capture.error(error, {
        extra: { patient: patientId, context: `patient.documents.list` },
      });
      toast.error();
    } finally {
      setIsQueryingDocuments(false);
    }
  }

  return {
    isQueryingDocuments,
    downloadProgress,
    lastDocQuery,
    documentListResult,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
    searchDocFilters,
  };
}

function getFilterValue(filterInput: string | undefined): string | undefined {
  if (!filterInput) return;
  const trimmedValue = filterInput.trim();
  return trimmedValue && trimmedValue.length > 0 ? trimmedValue : undefined;
}

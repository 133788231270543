import { Procedure, Practitioner } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { ProcedureRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { showPeriodDate } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { getReferenceResource } from "../shared";
import { ReferencesDisplay } from "../shared/references";

export function ProcedureContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ProcedureRowData) {
  const selectedProcedure = mappedConsolidated?.["Procedure"]?.[rowContents.id] as
    | Procedure
    | undefined;

  const valueString = selectedProcedure?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Procedure"
      sourceDocument={{
        id: selectedProcedure?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedProcedure, null, 2) : undefined}
    >
      <ProcedureDisplay
        procedure={selectedProcedure}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function ProcedureDisplay({
  procedure,
  tableRow,
  mappedConsolidated,
}: {
  procedure: Procedure | undefined;
  tableRow: ProcedureRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!procedure) {
    return null;
  }

  const practitionerReference = getReferenceResource<Practitioner>(
    procedure?.performer?.[0]?.actor,
    "Practitioner",
    mappedConsolidated
  );

  return (
    <>
      <DetailsDisplay
        details={{
          procedure: tableRow.procedure,
          status: tableRow.status,
          period: showPeriodDate({
            period: procedure.performedPeriod,
            fallbackDate: procedure.performedDateTime,
          }),
        }}
      />
      <CodesDisplay code={procedure.code} />
      <ReferencesDisplay
        practitioners={{
          refs: practitionerReference ? [practitionerReference] : undefined,
        }}
      />
    </>
  );
}

import { Skeleton } from "@chakra-ui/react";
import { useAnalyticsContext } from "@metriport/shared-internal";
import { useRedirectFunctions, WithAuthInfoProps } from "@propelauth/react";
import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateBaseURL as updateBaseURLOSS } from "../../../api/apiOSS";
import { setAuthTokenHeaders } from "../../../api/auth";
import { getCustomer } from "../../../api/customer";
import { getCustomerName, getCxId, getUserKeyIds } from "../../../api/propelauth";
import { getMapiAccess } from "../../../api/settings";
import { capture } from "../../../shared/capture";
import { getRole } from "../../../shared/roles";
import { AppStateActionType } from "../../contexts/app/reducer";
import useMetriportToast from "../../medical/shared-logic/useMetriportToast";
import { handleNavigation } from "../../settings/sandbox";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";
import { sleep } from "../../shared/util";
import { AppState, AppStateAction, initialState, reducer } from "./reducer";

/**
 * This is a workaround for the limitation with AppStateProvider not being executed as part of each
 * route's element - it runs regardless of the typed path/chosen route.
 *
 * Add here all the unauthenticated routes so it won't redirect the user to the login page.
 */
const unauthenticatedRoutes = ["/feedback"];

export type AppStateProviderProps = {
  authInfo: WithAuthInfoProps;
  children: ReactNode;
};

interface IAppStateContext {
  state: AppState;
  dispatch: Dispatch<AppStateAction>;
}

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = ({ authInfo, children }: AppStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isUserInSandbox, sandboxMap } = useIsUserInSandbox(state);
  const Analytics = useAnalyticsContext();
  const toast = useMetriportToast();

  const { redirectToLoginPage } = useRedirectFunctions();

  const location = useLocation();
  const navigate = useNavigate();
  const isUnauthenticatedRoute = unauthenticatedRoutes.some(r => location.pathname.startsWith(r));

  useEffect(() => {
    async function fetchData() {
      if (isUnauthenticatedRoute) return;

      if (!authInfo.isLoggedIn) {
        try {
          Analytics.reset();
        } catch (error) {
          console.error("Error resetting analytics", error);
        } finally {
          redirectToLoginPage();
        }
        return;
      }

      const { user, userClass, accessToken, refreshAuthInfo } = authInfo;
      try {
        const cxId = getCxId(authInfo);
        if (!cxId) {
          // Give some time for the WH from PropelAuth to arrive at our BE
          await sleep(1_000);
          await refreshAuthInfo();
        }
        const token = accessToken;
        setAuthTokenHeaders(token);

        const isSameCxId = state.customer?.id === cxId;
        const customer =
          state.customer && isSameCxId ? state.customer : await getCustomer(userClass, cxId);
        const keyIds = getUserKeyIds(userClass);
        const userRole = getRole(userClass);
        const companyName = getCustomerName(userClass);
        const mapiAccess = await getMapiAccess();

        Analytics.reset();
        customer &&
          Analytics.identify(userClass.userId, {
            companyName,
            cxId: customer.id,
            email: user.email,
          });
        customer &&
          Analytics.group(customer.id, {
            companyName,
            cxId: customer.id,
          });

        const isInSandbox = sandboxMap[user.userId] ?? false;

        updateBaseURLOSS(isInSandbox);
        handleNavigation(location.pathname, isInSandbox, navigate);

        dispatch({
          type: AppStateActionType.update,
          newState: {
            authToken: token,
            customer,
            user,
            userRole,
            isLoaded: true,
            keyIds,
            hasMapiAccess: mapiAccess.hasMapiAccess,
            isImpersonating: authInfo.isImpersonating,
          },
        });
      } catch (error) {
        const title =
          "Failed to initialize the application, please reach out to support@metriport.com";
        toast.error({ title });
        capture.error("Error initializing Dash's state", {
          extra: { context: `app.state.init`, error, msgDisplayed: title },
        });
      }
    }
    fetchData();
  }, [isUserInSandbox, authInfo]);

  // Prevent that the UI/components are rendered before we can check if the user is authenticated
  if (!isUnauthenticatedRoute && !state.isLoaded) return <Skeleton height="100vh" width="100vw" />;

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

export const useAppContext = () => useContext<IAppStateContext>(AppStateContext);

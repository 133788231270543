import { useColorMode, IconButton } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { PatientDTO, Facility } from "@metriport/api-sdk";
import dayjs from "dayjs";
import { EditIcon } from "@chakra-ui/icons";
import { IdContainer } from "@metriport/shared-internal";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function PatientGrid({
  patients,
  facilities,
  search,
  onPatientClick,
  isLoading,
  onPatientEdit,
}: {
  patients: PatientDTO[] | undefined;
  facilities: Facility[] | undefined;
  search: string;
  onPatientClick: (patientId: string) => void;
  isLoading: boolean;
  onPatientEdit: (patient: PatientDTO) => void;
}) {
  const { colorMode } = useColorMode();

  const patientHasExternalIds = hasExternalIds(patients);

  const rowData = patients?.map(patient => ({
    externalId: patient.externalId ?? "-",
    id: patient.id,
    name: `${patient.firstName} ${patient.lastName}`,
    dob: patient.dob,
    gender: patient.genderAtBirth,
    dateCreated: dayjs(patient.dateCreated).format("YYYY-MM-DD"),
    facility: getFacilityNames(facilities, patient),
  }));

  const columnDefs: ColDef[] = [
    { field: "externalId", hide: !patientHasExternalIds },
    {
      field: "id",
      hide: patientHasExternalIds,
      onCellClicked: () => null,
      cellRenderer: (params: { value: string }) => (
        <IdContainer value={params.value} entity={"Patient"} full={true} />
      ),
      maxWidth: 350,
    },
    { field: "name" },
    { field: "dob", maxWidth: 150 },
    { field: "gender", maxWidth: 100 },
    { field: "dateCreated", maxWidth: 150 },
    { field: "facility" },
    {
      field: "actions",
      maxWidth: 150,
      onCellClicked: () => null,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (!patients) {
          return null;
        }

        const patient = patients.find(f => f.id === props.data.id);

        if (!patient) {
          return null;
        }

        return (
          <IconButton
            // JUST SEND ID
            onClick={() => onPatientEdit(patient)}
            bg={"#748df0"}
            color={"white"}
            _hover={{ bg: "#879ced" }}
            aria-label="Edit Patient"
            icon={<EditIcon />}
          />
        );
      },
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onPatientClick(e.data.id);
    },
  };

  return (
    <AgGridReact
      className={colorMode === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
      loading={isLoading}
      headerHeight={60}
      groupDefaultExpanded={-1}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowData={rowData}
      quickFilterText={search}
      pagination={true}
      paginationPageSize={20}
      rowStyle={{ cursor: "pointer" }}
      suppressCellFocus={true}
    />
  );
}

function hasExternalIds(patients: PatientDTO[] | undefined): boolean {
  return patients?.some(patient => patient.externalId) ?? false;
}

function getFacilityNames(facilities: Facility[] | undefined, patient: PatientDTO): string {
  const facilityMap = new Map<string, string>();

  facilities?.forEach(facility => {
    facilityMap.set(facility.id, facility.name);
  });

  return patient.facilityIds.map(id => facilityMap.get(id)).join(", ");
}

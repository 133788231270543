import { Practitioner, Organization, Encounter, Condition, Location } from "@medplum/fhirtypes";
import { SidePanelSection } from "./sidepanel-section";
import { ConditionReferences } from "../../side-panel/references/condition";
import { PractitionerReference } from "../../side-panel/references/practitioner";
import { OrganizationReference } from "../../side-panel/references/organization";
import { EncounterReference } from "../../side-panel/references/encounter";
import { LocationReference } from "../../side-panel/references/location";

export function ReferencesDisplay({
  practitioners,
  organizations,
  encounters,
  conditions,
  locations,
}: {
  practitioners?: {
    title?: string;
    refs?: Practitioner[] | undefined;
  };
  organizations?: {
    title?: string;
    refs?: Organization[] | undefined;
  };
  encounters?: {
    title?: string;
    refs?: Encounter[] | undefined;
  };
  conditions?: {
    title?: string;
    refs?: Condition[] | undefined;
  };
  locations?: {
    title?: string;
    refs?: Location[] | undefined;
  };
}) {
  if (!practitioners?.refs && !organizations?.refs && !encounters?.refs && !conditions?.refs) {
    return null;
  }

  return (
    <SidePanelSection title="References">
      {encounters?.refs && encounters?.refs.length > 0 && (
        <EncounterReference title={encounters?.title} mb={4} encounters={encounters?.refs} />
      )}
      {locations?.refs && locations?.refs.length > 0 && (
        <LocationReference mb={4} title={locations?.title} locations={locations?.refs} />
      )}
      {practitioners?.refs && practitioners?.refs.length > 0 && (
        <PractitionerReference
          title={practitioners?.title}
          mb={4}
          practitioners={practitioners?.refs}
        />
      )}
      {organizations?.refs && organizations?.refs.length > 0 && (
        <OrganizationReference
          title={organizations?.title}
          mb={4}
          organizations={organizations?.refs}
        />
      )}
      {conditions?.refs && conditions?.refs.length > 0 && (
        <ConditionReferences title={conditions?.title} conditions={conditions?.refs} />
      )}
    </SidePanelSection>
  );
}

import { useState } from "react";
import dayjs from "dayjs";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useAnalyticsContext, Features, Actions } from "./analytics-context";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

type UseDownloadFile = {
  isDownloading: isDownloading;
  onDownloadFile: (
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ) => Promise<void>;
};

export function useDownloadFile(): UseDownloadFile {
  const toast = useMetriportToast();
  const metriportApi = useMetriportApi();
  const Analytics = useAnalyticsContext();

  const [isDownloading, setIsDownloading] = useState<isDownloading>({});

  async function onDownloadFile(
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ): Promise<void> {
    Analytics.emit(Actions.download, Features.document);
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: true,
        type: conversionType ?? "xml",
      },
    });
    try {
      if (conversionType) {
        toast.info({
          title: "Hold tight... This might take up to 30 seconds.",
          duration: EXPLAIN_TOAST_DURATION,
        });
      }
      const resp = await metriportApi.getDocumentUrl(docFilename, conversionType);

      const a = document.createElement("a");
      a.href = resp.url;
      a.download = docFilename;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      capture.error(err, { extra: { docId: docId, context: `patient.file.download` } });
      toast.error();
    }
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: false,
      },
    });
  }

  return { onDownloadFile, isDownloading };
}

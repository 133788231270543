import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DemographicsModal } from "../shared-components/demos-modal";
import { PatientFHIRData } from "../shared-components/fhir-sections";
import { fhirSections } from "../shared-components/fhir-sections/sections";
import { PatientsHeader } from "../shared-components/header";
import { MrModal } from "../shared-components/mr-modal";
import { PatientForm } from "../shared-components/patient-form";
import { PatientMatches } from "../shared-components/patient-matches";
import { DocumentProgressContainer } from "../shared-components/progress-bar";
import { RawDocuments } from "../shared-components/raw-documents";
import { PatientTabs } from "../shared-components/tabs";
import { usePatientConsolidatedContext } from "../shared-logic/consolidated-context";
import {
  PatientConsolidatedStateActionType,
  PatientQueryParamProps,
  PatientTab,
  getValidPatientTab,
} from "../shared-logic/consolidated-context/reducer";
import { useSharedContext } from "../shared-logic/shared-context";
import { useGetConsolidated } from "../shared-logic/useGetConsolidated";
import { useGetFacilities } from "../shared-logic/useGetFacilties";
import { useGetFilters } from "../shared-logic/useGetFilters";
import { useGetMedicalRecord } from "../shared-logic/useGetMedicalRecord";
import { useGetPatientInfo } from "../shared-logic/useGetPatientInfo";
import { useQueryDocuments } from "../shared-logic/useQueryDocuments";
import { useSubmitPatient } from "../shared-logic/useSubmitPatient";

export type SectionCounts = {
  sectionCounts: {
    reports: number;
    documents: number;
  };
};

export function PatientOverview({
  patientId,
  patientQueryParams,
  isImpersonating,
}: {
  patientId: string;
  patientQueryParams?: PatientQueryParamProps | undefined;
  isImpersonating?: boolean;
}) {
  const patientFormDisclosure = useDisclosure();
  const patientMRDisclosure = useDisclosure();
  const patientDemosDisclosure = useDisclosure();
  const patientMatchesDisclosure = useDisclosure();

  const [patientTab, setPatientTab] = useState<PatientTab>(
    getValidPatientTab(patientQueryParams?.patientTab)
  );

  const { sharedState } = useSharedContext();
  const { patientConsolidatedDispatch, patientConsolidatedState } = usePatientConsolidatedContext();
  const { createOrUpdatePatient, isSubmitting } = useSubmitPatient({
    patientId,
    onComplete: () => patientFormDisclosure.onClose(),
  });
  const { patient, isFetchingPatient, setPatient } = useGetPatientInfo({ patientId });
  const { filters } = useGetFilters();
  const { facilities } = sharedState.ehrDetails ? { facilities: [] } : useGetFacilities();
  const { existingMrStatus, isMrDownloading, onDownloadMR } = useGetMedicalRecord({ patientId });

  const documentsState = patientConsolidatedState[patientId]?.documents;
  const consolidatedState = patientConsolidatedState[patientId]?.consolidated;
  const lastDocQueryState = patientConsolidatedState[patientId]?.lastDocQuery;

  const {
    isQueryingDocuments,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
    downloadProgress,
    lastDocQuery,
    documentListResult,
    searchDocFilters,
  } = useQueryDocuments({
    patientId,
    disabled: !!documentsState,
  });

  const documentQueryComplete = !isQueryingDocuments;

  useEffect(() => {
    if (documentQueryComplete && !documentsState && !lastDocQueryState) {
      patientConsolidatedDispatch({
        type: PatientConsolidatedStateActionType.setPatientsDocuments,
        patientId,
        documents: documentListResult.documents,
        lastDocQuery,
      });
    }
  }, [documentQueryComplete, documentListResult, lastDocQuery]);

  const { consolidated, isConsolidatedLoading } = useGetConsolidated({
    patientId,
    canQueryConsolidated: documentQueryComplete && !consolidatedState,
  });

  useEffect(() => {
    if (consolidated) {
      patientConsolidatedDispatch({
        type: PatientConsolidatedStateActionType.setPatientsConsolidated,
        patientId,
        consolidatedBundle: consolidated,
      });
    }
  }, [consolidated]);

  function setPatientTabAndParams(patientTab: PatientTab) {
    setPatientTab(patientTab);
    patientQueryParams?.setPatientQueryParams({ patientTab });
  }

  useEffect(() => {
    if (patientQueryParams?.patientTab === "matches") {
      patientMatchesDisclosure.onOpen();
    }
  }, [patientQueryParams?.patientTab, patientMatchesDisclosure]);

  return (
    <>
      {/* V1 Going to add Breadcrumbs */}
      <Box p={5} maxW={"2560px"} mx={"auto"}>
        <PatientsHeader
          patient={patient}
          isQueryingDocuments={isQueryingDocuments}
          isFetchingPatient={isFetchingPatient}
          isDownloadingMr={isMrDownloading}
          selectedTab={patientTab}
          lastDocQuery={lastDocQueryState}
          actions={{
            onQueryPatientDocuments: async ({ override }) => {
              patientConsolidatedDispatch({
                type: PatientConsolidatedStateActionType.resetPatientsConsolidated,
                patientId,
              });

              await onQueryPatientDocuments({ override });
            },
            onEditPatient: sharedState.ehrDetails ? undefined : patientFormDisclosure.onOpen,
            onGenerateMedicalRecord: patientMRDisclosure.onOpen,
            onDisplayPatientDemos: patientDemosDisclosure.onOpen,
            onViewPatientMatches: sharedState.ehrDetails
              ? undefined
              : patientMatchesDisclosure.onOpen,
            onSetPatientTab: setPatientTabAndParams,
          }}
        />
        {patientTab === "fhir" && (
          <>
            <PatientTabs
              isImpersonating={isImpersonating}
              filters={filters}
              isIFrame={!!sharedState.ehrDetails}
            />
            <PatientFHIRData
              isImpersonating={isImpersonating}
              fhirSections={fhirSections}
              filters={filters}
              mappedConsolidated={consolidatedState}
              isConsolidatedLoading={isQueryingDocuments || isConsolidatedLoading}
              patientQueryParams={patientQueryParams}
            />
          </>
        )}
        {patientTab === "documents" && (
          <RawDocuments
            searchDocFilters={searchDocFilters}
            documents={documentsState}
            isQueryingDocuments={isQueryingDocuments}
            actions={{
              onSearchDocuments: onSearchPatientDocuments,
            }}
          />
        )}
      </Box>
      <DocumentProgressContainer
        isQueryingDocuments={isQueryingDocuments}
        total={downloadProgress.total}
        completed={downloadProgress.completed}
      />
      {patientMatchesDisclosure.isOpen && (
        <PatientMatches
          patient={patient}
          isOpen={patientMatchesDisclosure.isOpen}
          actions={{
            onClose: () => {
              setPatientTabAndParams("fhir");
              patientMatchesDisclosure.onClose();
            },
          }}
        />
      )}
      <PatientForm
        isOpen={patientFormDisclosure.isOpen}
        selectedPatient={patient}
        facilities={facilities}
        isSubmitting={isSubmitting}
        actions={{
          onCreateOrUpdatePatient: async patient => {
            const resp = await createOrUpdatePatient(patient);
            setPatient(resp);
          },
          onCloseForm: patientFormDisclosure.onClose,
        }}
      />
      <MrModal
        isOpen={patientMRDisclosure.isOpen}
        isDownloading={isMrDownloading}
        existingMrStatus={existingMrStatus}
        actions={{
          onCloseModal: patientMRDisclosure.onClose,
          onDownloadMR,
        }}
      />
      <DemographicsModal
        isOpen={patientDemosDisclosure.isOpen}
        patient={patient}
        actions={{
          onCloseModal: patientDemosDisclosure.onClose,
        }}
      />
    </>
  );
}
